<template>
  <v-row justify="center" align="stretch">
    <UserSettingsMenu v-if="!hideMenu" />
    <v-col cols="12" sm="10" md="10">
      <v-data-table :headers="headers" :items="localData" class="elevation-1">
        <template v-slot:top>
          <v-toolbar flat color="secondary" rounded>
            <v-toolbar-title class="white--text">Subscription</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" :return-value.sync="editedItem" max-width="500px" persistent>
              <template v-slot:activator="{ on }">
                <v-btn 
                  v-if="userClaims.admin && hideMenu" 
                  small 
                  color="primary" 
                  class="mb-2" 
                  v-on="on" 
                  rounded
                  :disabled="hasActiveSubscription"
                >
                  New Subscription
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row class="justify-center">
                      <v-col cols="12" sm="6" md="6">
                        <v-select
                          label="Tier Subscription"
                          v-model="selectedTierPlan"
                          :items="tierPlan"
                          item-text="label"
                          item-value="value"
                          hint="Select plan"
                          persistent-hint
                          outlined
                          @click.stop
                          @change="updatePlans"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="6" md="6">
                        <v-select
                          v-model="editedItem.contract"
                          :items="plans"
                          item-text="value.text"
                          item-value="value"
                          label="Load"
                          outlined
                          @change="calcExpiry"
                          required
                        />
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field v-model="editedItem.cost" prefix="₱" label="Cost" readonly></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-menu
                          v-model="dateMenu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model="editedItem.startDate"
                              label="Start Date"
                              prepend-icon="event"
                              readonly
                              hint="YYYY-MM-DD"
                              persistent-hint
                              v-on="on"
                              :rules="fieldRules"
                            ></v-text-field>
                          </template>
                          <v-date-picker 
                            v-model="editedItem.startDate" 
                            @input="dateMenu = false"
                            :min="today"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          v-model="editedItem.endDate"
                          label="Expiry"
                          prepend-icon="event"
                          hint="YYYY-MM-DD"
                          persistent-hint
                          readonly
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" small outlined @click="close">Cancel</v-btn>
                  <v-btn color="primary" small @click="save">Save</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:[`item.isActive`]="{ item }">
          <v-chip
            :color="item.isActive ? '#A5D6A7' : '#E57373'"
            text-color="white"
            small
          >
            {{ item.isActive ? 'Active' : 'Expired' }}
          </v-chip>
        </template>
        <template v-slot:[`item.cost`]="{ item }">
          <span class="font-weight-bold">{{ formatPrice(item.cost) }}</span>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import UserSettingsMenu from "./UserSettingsMenu";
import { settingsCollection } from "../../firebaseDb";

export default {
  props: {
    subscriptionHistory: { type: Array },
    hideMenu: { type: Boolean },
    role: { type: String }
  },
  computed: {
    ...mapState(["currentUser", "userClaims"]),
    formTitle() {
      return this.editedIndex === -1 ? 'New Subscription' : 'Edit Subscription';
    },
    localData() {
      return this.subscriptionHistory;
    },
    hasActiveSubscription() {
      return this.localData.some(subscription => subscription.isActive);
    }
  },
  components: {
    UserSettingsMenu
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    subscriptionHistory(newVal, oldVal) {
      console.log("Subscription History: ", newVal);
      console.log("Prop change: ", newVal, " | was: ", oldVal);
    }
  },
  data: () => ({
    fieldRules: [v => !!v || "Field is required"],
    today: new Date().toISOString().substr(0, 10),
    dialog: false,
    headers: [
      { text: "Status", value: "isActive" },
      { text: "Plan", value: "plan"},
      { text: "Load", value: "contract.text" },
      { text: "Cost", value: "cost" },
      {
        text: "Start Date",
        align: "start",
        sortable: false,
        value: "startDate"
      },
      { text: "Expiry", value: "endDate" },
    ],
    editedIndex: -1,
    editedItem: {
      startDate: moment().format("YYYY-MM-DD"),
      endDate: "",
      contract: "",
      cost: "",
      plan: "",
      isActive: ""
    },
    selectedTierPlan: '',
    tierPlan: [
      {label: "Tier 1", value: "tier1"},
      {label: "Tier 2", value: "tier2"},
      {label: "Tier 3", value: "tier3"}, 
    ],
    tier1: [],
    tier2: [],
    tier3: [],
    defaultItem: {
      startDate: moment().format("YYYY-MM-DD"),
      endDate: "",
      contract: "",
      cost: "",
      plan: ""
    },
    dateMenu: false,
    plans: []
  }),
  created() {
    this.fetchPricingData();
  },
  methods: {
    formatPrice(price) {
      return `₱${parseFloat(price).toFixed(2)}`;
    },
    updatePlans() {
      // Reset the form fields to default values
      this.resetForm();

      if (this.selectedTierPlan === 'tier1') {
        this.editedItem.plan = "Tier1",
        this.plans = this.tier1.filter(item => item.text && item.price).map(item => ({
          text: item.text,
          value: item
        }));
      } else if (this.selectedTierPlan === 'tier2') {
        this.editedItem.plan = "Tier2",
        this.plans = this.tier2.filter(item => item.text && item.price).map(item => ({
          text: item.text,
          value: item
        }));
      } else if (this.selectedTierPlan === 'tier3') {
        this.editedItem.plan = "Tier3",
        this.plans = this.tier3.filter(item => item.text && item.price).map(item => ({
          text: item.text,
          value: item
        }));
      }
    },
    resetForm() {
      // Reset the form fields to their default values
      this.editedItem = Object.assign({}, this.defaultItem);
      this.plans = [];
      this.dateMenu = false;
    },
    calcExpiry(val) {
      let monthsToAdd = 0;
      if (this.editedItem.contract.text === "Monthly") {
        monthsToAdd = 1;
      } else if (this.editedItem.contract.text === "Quarterly") {
        monthsToAdd = 3;
      } else if (this.editedItem.contract.text === "Annually") {
        monthsToAdd = 12;
      }

      this.editedItem.endDate = moment(this.editedItem.startDate)
        .add(monthsToAdd, 'months')
        .format("YYYY-MM-DD");

        // depending on the selected load, update the cost 
      this.editedItem.cost = val.price;
    },
    editItem(item) {
      this.editedIndex = this.subscriptionHistory.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.resetForm();
        this.editedIndex = -1;
      });
    },
    save() {
      const currentDate = moment().format("YYYY-MM-DD");
      this.editedItem.isActive = moment(currentDate).isBetween(this.editedItem.startDate, this.editedItem.endDate, null, '[]');

      console.log("isActive");
      this.$emit("newSubscription", {
        editedIndex: this.editedIndex,
        editedItem: this.editedItem
      });
      this.close();
    },
    fetchPricingData() {
      this.loading = true;
      settingsCollection
        .doc("user")
        .get()
        .then((querySnapshot) => {
          if (querySnapshot.exists) {
            this.tier1 = querySnapshot.data().pricing || [];
            this.tier2 = querySnapshot.data().pricing2 || [];
            this.tier3 = querySnapshot.data().pricing3 || [];
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log("Error fetching updated data: ", err);
          this.loading = false;
        });
    },
  }
};
</script>

<style>
.active-subscription {
  background-color: #e0f7fa;
}
</style>
