<template>
  <v-responsive min-width="500px">
    <v-card flat>
      <v-card-title> Edit User </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="formValid" @submit.prevent="prepSubmit">
          <v-text-field label="Name" v-model="user.name" disabled readonly />
          <v-text-field label="Email" v-model="user.email" disabled readonly />
          <v-row>
            <v-select
              class="col-6 col-sm-4 col-md-4"
              v-model="userRole"
              :items="roles"
              label="Role"
              :rules="fieldRules"
              @change="onRoleChange"
              required
            />
            <v-select
              class="col-6 col-sm-4 col-md-4"
              v-if="showTierSelect"
              v-model="user.role"
              :items="userRoles"
              label="Tier"
              :rules="fieldRules"
              required
              readonly
            />
          </v-row>

          <UserSubscription
            v-if="showTierSelect"
            :subscriptionHistory="subHist"
            :role="user.role"
            @newSubscription="updateSubscription($event)"
            hideMenu
          />
          <v-checkbox
            class="mx-4"
            v-if="!savedVerified"
            v-model="fbUser.emailVerified"
            label="Verify user"
          />
          <v-banner v-if="savedVerified" single-line class="my-3">
            <v-icon slot="icon" color="success" size="36">
              mdi-check-decagram-outline
            </v-icon>
            This user has been verified
          </v-banner>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="cancelEdit"
              small
              outlined
              >cancel</v-btn
            >
            <v-btn
              color="primary"
              type="submit"
              small
              :disabled="!formValid"
              >Save</v-btn
            >
          </v-card-actions>
          <v-snackbar :color="snackColor" v-model="snackbar" :timeout="2000">{{
            response
          }}</v-snackbar>
        </v-form>
        
      </v-card-text>
    </v-card>
  </v-responsive>
</template>

<script>
import UserSubscription from "./UserSubscription";
import { db, functions } from "../../firebaseDb";

export default {
  components: {
    UserSubscription,
  },
  data: () => ({
    // snackbar data
    snackbar: false,
    response: "",
    snackColor: "black",

    savedVerified: false,
    savedRole: null,
    formValid: true,
    fieldRules: [(v) => !!v || "Field is required"],
    user: {},
    subHist: [],
    roles: [
      { text: "Admin/Editor", value: "admin" },
      { text: "Contributor", value: "contributor" },
      { text: "User", value: "user" },
    ],
    userRoles: [
      { text: "Tier 1", value: "Tier1" },
      { text: "Tier 2", value: "Tier2" },
      { text: "Tier 3", value: "Tier3" },
      { text: "Tier 4 - Free Tier", value: "Tier4" },
    ],
    fbUser: {},
    userRole: null,
  }),
  computed: {
    showTierSelect() {
      return this.userRole === "user";
    },
    activeSubscription() {
      return this.subHist.find(sub => sub.isActive);
    }
  },
  watch: {
    subHist(nVal, oVal) {
      console.log("subHist changed: " + nVal + " | was: " + oVal);
    },
    'edited.editedItem.plan': {
      handler(newVal) {
        this.user.role = newVal;
      },
      deep: true,
    },
  },
  mounted() {
    let dbRef = db.collection("users").doc(this.$route.params.id);
    dbRef
      .get()
      .then((doc) => {
        this.user = doc.data();
        if (this.user.subscription) {
          this.subHist = this.user.subscription;
          // const today = moment().format("YYYY-MM-DD");
          // this.subHist = this.user.subscription.map(sub => ({
          //   ...sub,
          //   isActive: sub.startDate <= today && sub.endDate >= today
          // }));
        }
        if (!this.user.isAdmin) {
          this.user.isAdmin = false;
        }
        this.savedRole = this.user.role;

        // Set default roles when mounted
        if (this.user.role.includes('Tier')) {
          this.userRole = 'user';
        } else {
          this.userRole = this.user.role || 'user';
          if (this.userRole === 'user') {
            this.user.role = 'Tier4';
          }
        }

        console.log("retrieving user");
        let getUser = functions.httpsCallable("getUser");
        getUser({ email: this.user.email }).then((response) => {
          this.fbUser = response.data;
          this.user.name = this.fbUser.displayName;
          this.user.email = this.fbUser.email;
          this.savedVerified = this.fbUser.emailVerified;
        });
      })
      .catch((error) => {
        console.log(error);
      });
  },
  methods: {
    cancelEdit() {
      this.$router.push('/user-list');
    },
    onRoleChange(value) {
      if (value !== 'user') {
        this.user.role = value;
      } else if (value === 'user' && this.activeSubscription) {
        this.user.role = this.activeSubscription.plan;
      } else {
        this.user.role = this.userRoles[3].value;
      }
    },
    prepSubmit() {
      event.preventDefault();
      this.$refs.form.validate();
      this.user.subscription = this.subHist;
      this.updateUser();
    },
    updateUser() {
      // remove Vuejs observer and save data to firestore
      this.user.verified = this.fbUser.emailVerified;
      let newUser = { ...this.user };
      let dbRef = db.collection("users").doc(this.$route.params.id);
      dbRef
        .update(newUser)
        .then(() => {
          let data;
          if (this.savedRole !== this.user.role) {
            switch (this.user.role) {
              case "admin":
                this.user.isAdmin = true;
                data = {
                  uid: this.$route.params.id,
                  role: { admin: true },
                };
                break;
              case "contributor":
                this.user.isAdmin = false;
                data = {
                  uid: this.$route.params.id,
                  role: { admin: false },
                };
                break;
              default:
                this.user.isAdmin = false;
                data = {
                  uid: this.$route.params.id,
                  role: { admin: null },
                };
            }
            let addMessage = functions.httpsCallable("setUserRole");
            addMessage(data);
          }
          if (this.savedVerified !== this.fbUser.emailVerified) {
            data = { uid: this.fbUser.uid, email: this.fbUser.email };
            let addMessage = functions.httpsCallable("setUserVerification");
            addMessage(data);
          }
          this.snackbar = true;
          this.snackColor = "success";
          this.response = "User successfully updated!";
          setTimeout(() => this.$router.push("/user-list"), 1000);
        })
        .catch((error) => {
          console.log(error);
          this.snackbar = true;
          this.snackColor = "fail";
          this.response = error;
        });
    },

    updateSubscription(edited) {
      console.log("New Entry subscription", edited);
      if (edited.editedIndex > -1) {
        this.subHist.splice(edited.editedIndex, 1, edited.editedItem);
      } else {
        this.subHist.push(edited.editedItem);
      }
      this.user.role = edited.editedItem.plan;
      this.user.nextCheck = edited.editedItem.endDate; // EDIT THIS IF YOU PLAN TO ADD NEW SUBSCRIPTION TO USERS WITH EXISTING SUBSCRIPTION
      console.log("User : ", JSON.stringify(this.user, null,2));
    },
  },
};
</script>
